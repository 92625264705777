import { useEffect, useState } from "react";
import _ from "lodash";
import { InputField } from "../../../../components/controls/InputField";
import ButtonControl from "../../../../components/controls/ButtonControl";
import { DialogControl } from "../../../../components/controls/DialogControl";

const PrincipalSurveyDetails = ({ data, saveSurvey }) => {
  const [surveyQnA, setSurveyQnA] = useState(null);
  const [validateError, setValidateError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [principalSurveyData, setPrincipalSurveyData] = useState(null);
  const [validateErrorMsg, setValidateErrorMsg] = useState(
    "Please make sure all fields have been completed."
  );

  useEffect(() => {
    if (data) {
      const surveyFieldMap = _.reduce(
        _.orderBy(
          data.SurveyTemplate.SurveyTemplateFields,
          ["Sequence"],
          ["asc"]
        ),
        (newMap, x) => {
          const surveyItem = {
            FieldDisplayText: x.DisplayText,
            Title: x.Title,
            Value: null,
            SurveyFieldType: x.SurveyFieldType,
            IsOptional: x.IsOptional,
            SurveyTemplateFieldId: x.Id,
            Options:
              _.map(x.Options, (y) => {
                if (x.SurveyFieldType == 2) {
                  return {
                    Text: y,
                    Value: y,
                  };
                } else
                  return {
                    label: y,
                    value: y,
                  };
              }) ?? [],
          };
          if (!_.isEmpty(data.SurveySections)) {
            const surveyItemDetails = _.find(
              data.SurveySections,
              (ds) => ds.SurveyTemplateFieldId === x.Id
            );
            let value = surveyItemDetails?.Value ?? null;

            if (x.SurveyFieldType == 3) {
              value = value?.split(";");
            }
            surveyItem.SurveyContents = surveyItemDetails?.SurveyContents;
            surveyItem["Value"] = value;
            surveyItem.SurveySectionId = surveyItemDetails?.Id ?? undefined;
          }
          newMap.push(surveyItem);
          return newMap;
        },
        []
      );

      let textAreaField = [];
      const likertGrouped = _.reduce(
        surveyFieldMap,
        (groupedFields, field, i) => {
          if (field.SurveyFieldType == 5) {
            groupedFields.push(field);
          } else textAreaField.push(field);

          if (surveyFieldMap.length - 1 === i) {
            groupedFields = [...groupedFields, ...textAreaField];
          }
          return groupedFields;
        },
        []
      );
      setSurveyQnA(likertGrouped);
      const copyData = [...data.SurveyTemplate.SurveyLikertMap];
      const dataValues = _.map(copyData, (x) => x.Value);
      for (let i = 0; i < _.max(dataValues); i++) {
        if (!dataValues.includes(i + 1)) {
          copyData.push({
            Value: i + 1,
            Title: "",
          });
        }
      }

      setPrincipalSurveyData({
        ...data,
        SurveyTemplate: {
          ...data.SurveyTemplate,
          SurveyLikertMap: _.orderBy(copyData, ["Value"], ["asc"]),
        },
      });
    }
  }, [data]);

  const handleOnSave = async (condition) => {
    let result;
    setSaving(true);
    const copiedData = { ...principalSurveyData };
    copiedData.SurveySections = surveyQnA;
    if (condition === "NEXT") {
      let commentQA = surveyQnA.find((x) => x.Title === "Comments");
      if (!isNaN(parseFloat(commentQA?.Value)) && isFinite(commentQA?.Value)) {
        setValidateErrorMsg("Comment section must not be numeric.");
        setValidateError(true);
      } else {
        const isSurveyFilledOut = !_.isEmpty(surveyQnA)
          ? _.every(
              surveyQnA.filter((y) => !y.IsOptional),
              (x) => (Array.isArray(x.Value) ? x.Value.length > 0 : x.Value)
            )
          : true;
        if (isSurveyFilledOut) {
          copiedData.SurveyStatusType = 2;
          result = saveSurvey(copiedData, "NEXT");
        } else setValidateError(true);
      }
      setSaving(false);
    } else {
      if (copiedData.SurveyStatusType !== 2) {
        copiedData.SurveyStatusType = 1;
      }
      result = await saveSurvey(copiedData, "SUBMIT");
      setSaving(false);
    }
  };

  const handleUpdateSurvey = (value, fieldName, surveyField) => {
    const copySurveyQnA = [...surveyQnA];
    const surveyFieldIndex = _.findIndex(
      surveyQnA,
      (x) => x.SurveyTemplateFieldId === surveyField.SurveyTemplateFieldId
    );
    if (surveyField.SurveyFieldType == 4) {
      surveyField["Value"] = value.FriendlyFileName;
      if (!_.isEmpty(surveyField.SurveyContents)) {
        let copySurveyContent = { ...surveyField.SurveyContents.first() };
        copySurveyContent.FileId = value.FileId;
        copySurveyContent.File = value;
        surveyField.SurveyContents = [copySurveyContent];
      } else
        surveyField.SurveyContents = [
          {
            FileId: value.FileId,
            File: value,
            SurveySectionId: surveyField.SurveySectionId,
            SurveyContentType: 2,
          },
        ];
    } else {
      surveyField["Value"] = value;
    }
    copySurveyQnA.splice(surveyFieldIndex, 1, surveyField);
    setSurveyQnA(copySurveyQnA);
  };

  const renderLikertHeader = () => {
    let calculatedWidth;
    if (principalSurveyData?.SurveyTemplate.IncludeLikertNA) {
      calculatedWidth = {
        width: `calc(100% / (${principalSurveyData?.SurveyTemplate.SurveyLikertMap?.length} + 1))`,
      };
    } else {
      calculatedWidth = {
        width: `calc(100% / ${principalSurveyData?.SurveyTemplate.SurveyLikertMap?.length})`,
      };
    }
    return (
      <div className="top-item-wrapper">
        <div className="top-item-row">
          <div className="item-value-wrapper">
            {_.map(
              principalSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div className="item-value" key={i} style={calculatedWidth}>
                  {x.Title}
                </div>
              )
            )}
            {principalSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}></div>
            )}
          </div>
        </div>
        <div className="top-item-row">
          <div className="item-value-wrapper">
            {_.map(
              principalSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div className="item-value" key={i} style={calculatedWidth}>
                  {x.Value}
                </div>
              )
            )}
            {principalSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}>
                N/A
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLikertFields = (likertField, likertIndex) => {
    let calculatedWidth;
    if (principalSurveyData?.SurveyTemplate.IncludeLikertNA) {
      calculatedWidth = {
        width: `calc(100% / (${principalSurveyData?.SurveyTemplate.SurveyLikertMap?.length} + 1))`,
      };
    } else {
      calculatedWidth = {
        width: `calc(100% / ${principalSurveyData?.SurveyTemplate.SurveyLikertMap?.length})`,
      };
    }
    return (
      <>
        <div className="item-row" key={likertIndex}>
          <div className="item-question">{likertField?.FieldDisplayText}</div>
          <div className="item-value-wrapper">
            {_.map(
              principalSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div className="item-value" key={i} style={calculatedWidth}>
                  <input
                    onChange={() =>
                      handleUpdateSurvey(
                        x.Value,
                        likertField?.FieldDisplayText,
                        likertField
                      )
                    }
                    checked={x.Value == likertField?.Value}
                    type="radio"
                  />
                </div>
              )
            )}
            {principalSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}>
                <input
                  onChange={() =>
                    handleUpdateSurvey(
                      "N/A",
                      likertField?.FieldDisplayText,
                      likertField
                    )
                  }
                  checked={"N/A" == likertField?.Value}
                  type="radio"
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderOtherSurveyFields = (field) => {
    return (
      <div
        className={
          "item-row" +
          (field?.SurveyFieldType == 1 ? " item-row-textarea" : " ")
        }
      >
        <div className="item-question">{field?.FieldDisplayText}</div>

        {field?.SurveyFieldType == 1 && (
          <div className="item-value-wrapper">
            <InputField
              type="textarea"
              maxLength={250}
              value={field?.Value}
              fieldName={field?.FieldDisplayText}
              onChange={(value, fieldName) =>
                handleUpdateSurvey(value, fieldName, field)
              }
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {principalSurveyData && (
        <>
          <DialogControl
            title="Missing Fields"
            subTitle={validateErrorMsg}
            openDialog={validateError}
            disableXButton={true}
            onOk={() => setValidateError(false)}
          />
          <div className="survey-container">
            <div className="survey">
              {renderLikertHeader()}
              {_.map(surveyQnA, (field, i) => {
                if (field.SurveyFieldType == 5) {
                  return renderLikertFields(field, i);
                } else return renderOtherSurveyFields(field);
              })}
              <div className="survey-footer">
                <ButtonControl
                  type={"cancel"}
                  loading={saving}
                  onClick={handleOnSave}
                >
                  Finish Later
                </ButtonControl>
                <ButtonControl
                  type={"okay"}
                  loading={saving}
                  onClick={() => handleOnSave("NEXT")}
                >
                  Next
                </ButtonControl>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PrincipalSurveyDetails;
