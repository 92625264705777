import { useEffect, useState } from "react";
import _ from "lodash";
import PrintLayout from "../../../../components/layout/PrintLayout";
import { InputField } from "../../../../components/controls/InputField";
import moment from "moment";

const TeacherSurveyPrintEmptyScreen = ({ data, printingCompleted }) => {
  const [surveyQnA, setSurveyQnA] = useState(null);
  const [print, setPrint] = useState(null);
  const [teacherSurveyData, setTeacherSurveyData] = useState(null);
  useEffect(() => {
    if (data) {
      const surveyFieldMap = _.reduce(
        _.orderBy(
          data.SurveyTemplate.SurveyTemplateFields,
          ["Sequence"],
          ["asc"]
        ),
        (newMap, x) => {
          const surveyItem = {
            FieldDisplayText: x.DisplayText,
            Title: x.Title,
            Value: null,
            SurveyFieldType: x.SurveyFieldType,
            SurveyTemplateFieldId: x.Id,
            Options:
              _.map(x.Options, (y) => {
                if (x.SurveyFieldType == 2) {
                  return {
                    Text: y,
                    Value: y,
                  };
                } else
                  return {
                    label: y,
                    value: y,
                  };
              }) ?? [],
          };
          if (!_.isEmpty(data.SurveySections)) {
            const surveyItemDetails = _.find(
              data.SurveySections,
              (ds) => ds.SurveyTemplateFieldId === x.Id
            );
            let value = surveyItemDetails?.Value ?? null;

            if (x.SurveyFieldType == 3) {
              value = value?.split(";");
            }
            surveyItem.SurveyContents = surveyItemDetails?.SurveyContents;
            surveyItem["Value"] = value;
            surveyItem.SurveySectionId = surveyItemDetails?.Id ?? undefined;
          }
          newMap.push(surveyItem);
          return newMap;
        },
        []
      );

      let textAreaField = [];
      const likertGrouped = _.reduce(
        surveyFieldMap,
        (groupedFields, field, i) => {
          if (field.SurveyFieldType == 5) {
            groupedFields.push(field);
          } else textAreaField.push(field);

          if (surveyFieldMap.length - 1 === i) {
            groupedFields = [...groupedFields, ...textAreaField];
          }
          return groupedFields;
        },
        []
      );
      setSurveyQnA(likertGrouped);
      const copyData = [...data.SurveyTemplate.SurveyLikertMap];
      const dataValues = _.map(copyData, (x) => x.Value);
      for (let i = 0; i < _.max(dataValues); i++) {
        if (!dataValues.includes(i + 1)) {
          copyData.push({
            Value: i + 1,
            Title: "",
          });
        }
      }

      setPrint(moment().toISOString());
      setTeacherSurveyData({
        ...data,
        SurveyTemplate: {
          ...data.SurveyTemplate,
          SurveyLikertMap: _.orderBy(copyData, ["Value"], ["asc"]),
        },
      });
    }
  }, [data]);

  const renderLikertHeader = () => {
    let calculatedWidth;
    if (teacherSurveyData?.SurveyTemplate.IncludeLikertNA) {
      calculatedWidth = {
        width: `calc(100% / (${teacherSurveyData?.SurveyTemplate.SurveyLikertMap?.length} + 1))`,
      };
    } else {
      calculatedWidth = {
        width: `calc(100% / ${teacherSurveyData?.SurveyTemplate.SurveyLikertMap?.length})`,
      };
    }
    return (
      <div className="top-item-wrapper">
        <div className="top-item-row">
          <div className="item-value-wrapper">
            {_.map(
              teacherSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div
                  className="item-value"
                  key={"sl_h1_" + i}
                  style={calculatedWidth}
                >
                  {x.Title}
                </div>
              )
            )}
            {teacherSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}></div>
            )}
          </div>
        </div>
        <div className="top-item-row">
          <div className="item-value-wrapper">
            {_.map(
              teacherSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div
                  className="item-value"
                  key={"sl_h2_" + i}
                  style={calculatedWidth}
                >
                  {x.Value}
                </div>
              )
            )}
            {teacherSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}>
                N/A
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLikertFields = (likertField, index) => {
    let calculatedWidth;
    if (teacherSurveyData?.SurveyTemplate.IncludeLikertNA) {
      calculatedWidth = {
        width: `calc(100% / (${teacherSurveyData?.SurveyTemplate.SurveyLikertMap?.length} + 1))`,
      };
    } else {
      calculatedWidth = {
        width: `calc(100% / ${teacherSurveyData?.SurveyTemplate.SurveyLikertMap?.length})`,
      };
    }
    return (
      <>
        <div className="item-row" key={"lf_" + index}>
          <div className="item-question">{likertField?.FieldDisplayText}</div>
          <div className="item-value-wrapper">
            {_.map(
              teacherSurveyData?.SurveyTemplate.SurveyLikertMap,
              (x, i) => (
                <div
                  className="item-value"
                  key={"sl_" + i}
                  style={calculatedWidth}
                >
                  <input type="radio" />
                </div>
              )
            )}
            {teacherSurveyData?.SurveyTemplate.IncludeLikertNA && (
              <div className="item-value" style={calculatedWidth}>
                <input type="radio" />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderOtherSurveyFields = (field) => {
    return (
      <div
        key={"field_" + field.Id}
        className={
          "item-row" +
          (field?.SurveyFieldType == 1 ? " item-row-textarea" : " ")
        }
      >
        <div className="item-question">{field?.FieldDisplayText}</div>

        {field?.SurveyFieldType == 1 && (
          <div className="item-value-wrapper">
            <InputField
              type="textarea"
              maxLength={250}
              value={field?.Value}
              fieldName={field?.FieldDisplayText}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <PrintLayout
      print={print}
      cleanUp={printingCompleted}
      className={"survey-print"}
    >
      <div className="survey-screen-header">
        <div className="survey-header-row">
          <div className="survey-header-wrapper">
            <h1>{data?.Name}</h1>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Survey Given By:</div>
            <div className="survey-subheader-right">{data?.User?.FullName}</div>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Teacher Surveyed:</div>
            <div className="survey-subheader-right">
              {data?.SurveyedUser?.FullName}
            </div>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">School:</div>
            <div className="survey-subheader-right">{data?.School?.Name}</div>
          </div>
          {/* <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Start Date:</div>
                    <div className="survey-subheader-right"> {FormatUTCDateTimeLong(data?.SurveyDate)}</div>
                </div> */}
        </div>
      </div>
      <div className="survey-container">
        <div className="survey">
          {renderLikertHeader()}
          {_.map(surveyQnA, (field, i) => {
            if (field.SurveyFieldType == 5) {
              return renderLikertFields(field, i);
            } else return renderOtherSurveyFields(field);
          })}
        </div>
      </div>
    </PrintLayout>
  );
};

export default TeacherSurveyPrintEmptyScreen;
