import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";

import {
  useReportManager,
  useAppUserManager,
} from "../../../hooks/useManagers";
import * as gaMgr from "../../../managers/GoogleAnalyticsManager";
import { EventTypeEnum } from "../../../managers/GoogleAnalyticsManager";
import { useHookstate } from "@hookstate/core";
import MainLayout from "../../../components/layout/MainLayout";
import ScreenWrapper from "../../../components/layout/screenWrapper";
import ReportFilter from "../components/reports/filter";
import { getReportByUrl } from "../components/reports/reports";
import {
  getDefaultReportParameters,
  getReportParametersFromQueryString,
} from "../components/reports/reportParameters";
import {
  getDefaultRequiredReportParameters,
  getMissingRequiredReportParameters,
  getDefaultMissingRequiredReportParameters,
} from "../components/reports/requiredReportParameters";
import { getDefaultReportParametersDisplay } from "../components/reports/reportParametersDisplay";
import ButtonControl from "../../../components/controls/ButtonControl";
import globalAuthStateStore from "../../../stores/globalAuthStateStore";
import AccessDeny from "../../../components/security/AccessDeny";
import config from "../../../config.json";

import "../reportMgmt.scss";
import _ from "lodash";

const ReportMgmt = () => {
  const viewerRef = useRef(null);
  const { url } = useParams();

  //console.log('url: ', url);

  const navigate = useNavigate();

  const location = useLocation();

  //console.log('location: ', location);

  const visited = location.state;
  //console.log("Visited?", visited);

  const reportManager = useReportManager();
  const appUserManager = useAppUserManager();
  const globalAuthState = useHookstate(globalAuthStateStore);
  const authState = globalAuthState.get();

  const [accessToken, setAccessToken] = useState(null);

  const [hasPermission, setHasPermission] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [reportLoaded, setReportLoaded] = useState(false);
  const [report, setReport] = useState(null);
  const [initialReportParameters, setInitialReportParameters] = useState(null);

  const [orgs, setOrgs] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [schoolYears, setSchoolYears] = useState(null);

  const [orgsLoaded, setOrgsLoaded] = useState(false);
  const [clustersLoaded, setClustersLoaded] = useState(true);
  const [schoolYearsLoaded, setSchoolYearsLoaded] = useState(false);

  const [isRunReportButtonEnabled, setIsRunReportButtonEnabled] =
    useState(false);

  useEffect(() => {
    if (url && appUserManager) {
      setLoading(true);
      setReportLoaded(false);
      const report = getReportByUrl(url);
      const permissions = report.getPermissions();
      const permissionCount = permissions.filter(
        (p) => appUserManager.AccessCheck(p) === true
      );
      const verifyHasPermission = permissionCount.length > 0;
      setHasPermission(verifyHasPermission);
      if (verifyHasPermission === true) {
        setReport(report);
      }
    }
  }, [url, appUserManager]);

  useEffect(() => {
    if (reportManager && authState?.accessToken) {
      const promises = [];

      promises.push(
        reportManager.OrgManager.loadOrgTree()
          .then(() => {
            setOrgs(reportManager.OrgManager.OrgsOnly());
            setOrgsLoaded(true);
          })
          .catch(() => setLoadingError(true))
      );

      promises.push(
        reportManager.ClusterManager.query(
          "x => x.IsActive == true && x.IsDeleted == false",
          undefined,
          true
        )
          .then((c) => {
            setClusters(c.Items);
            setClustersLoaded(true);
          })
          .catch(() => setLoadingError(true))
      );

      if (reportManager?.AppUserState?.currentSchoolYear && !schoolYears) {
        const currentSchoolYear =
          reportManager?.AppUserState?.currentSchoolYear + 1;
        promises.push(
          reportManager.SchoolYearManager.query(
            "x => x.IsActive == true && x.IsDeleted == false && x.SchoolYearIdentifier <= currentSchoolYear",
            { currentSchoolYear }
          )
            .then((sy) => {
              //const sys = sy.Items.filter((sy) => sy.ClientId === null);
              const sysMapped = sy.Items.filter(
                (cy) => new Date(cy.SchoolYearStart) <= new Date()
              )
                .map((sy) => ({
                  SchoolYearIdentifier: sy.SchoolYearIdentifier,
                  Title: `${sy.SchoolYearIdentifier - 1}-${
                    sy.SchoolYearIdentifier
                  }`,
                  SchoolYearStart: sy.SchoolYearStart,
                  SchoolYearEnd: sy.SchoolYearEnd,
                }))
                .sort((a, b) =>
                  a.SchoolYearIdentifier > b.SchoolYearIdentifier ? 1 : -1
                );

              setSchoolYears(sysMapped);
              setSchoolYearsLoaded(true);
            })
            .catch((e) => {
              setLoadingError(true);
            })
        );
      }

      // Run all promises concurrently
      Promise.all(promises)
        .catch((err) => {
          console.error("Error fetching data:", err);
        })
        .finally(() => setLoading(false));
    }
  }, [
    reportManager,
    reportManager?.AppUserState?.currentSchoolYear,
    authState?.accessToken,
  ]);

  useEffect(() => {
    if (authState?.accessToken) {
      setAccessToken(authState.accessToken);
    }
  }, [authState?.accessToken]);

  useEffect(() => {
    if (
      reportManager &&
      accessToken &&
      orgsLoaded &&
      clustersLoaded &&
      reportManager?.AppUserState?.selectedSchoolYear &&
      schoolYearsLoaded &&
      report
    ) {
      let reportParameters = reportManager.ReportParameters;
      reportParameters =
        report?.getIndividualDefaultReportParameters ??
        getDefaultReportParameters();

      if (window.location.href.includes("?")) {
        const searchParams = getReportParametersFromQueryString(
          window.location.href.substring(window.location.href.indexOf("?"))
        );

        if (
          searchParams.rt &&
          searchParams.rt !== "" &&
          searchParams.rt !== "null"
        ) {
          reportParameters.reportType = searchParams.rt;
        }
        if (
          searchParams.oId &&
          searchParams.oId !== "" &&
          searchParams.oId !== "null"
        ) {
          reportParameters.organizationId = searchParams.oId;
        }
        if (
          searchParams.dId &&
          searchParams.dId !== "" &&
          searchParams.dId !== "null"
        ) {
          reportParameters.districtId = searchParams.dId;
        }
        if (
          searchParams.sId &&
          searchParams.sId !== "" &&
          searchParams.sId !== "null"
        ) {
          reportParameters.schoolId = searchParams.sId;
        }
        if (
          searchParams.cId &&
          searchParams.cId !== "" &&
          searchParams.cId !== "null"
        ) {
          reportParameters.clusterId = searchParams.cId;
        }
        if (
          searchParams.tIds &&
          searchParams.tIds !== "" &&
          searchParams.tId !== "null"
        ) {
          reportParameters.teacherUserIds = searchParams.tIds;
        }
        if (
          searchParams.sy &&
          searchParams.sy !== "" &&
          searchParams.sy !== "null"
        ) {
          reportParameters.schoolYear = searchParams.sy;
        }
        if (
          searchParams.sdr &&
          searchParams.sdr !== "" &&
          searchParams.sdr !== "null"
        ) {
          reportParameters.selectDateRange = searchParams.sdr;
        }
        if (
          searchParams.sd &&
          searchParams.sd !== "" &&
          searchParams.sd !== "null"
        ) {
          reportParameters.startDate = searchParams.sd;
        }
        if (
          searchParams.ed &&
          searchParams.ed !== "" &&
          searchParams.ed !== "null"
        ) {
          reportParameters.endDate = searchParams.ed;
        }
        if (
          searchParams.isal &&
          searchParams.isal !== "" &&
          searchParams.isal !== "null"
        ) {
          reportParameters.isSummarizeAtLevel = !!searchParams.isal;
        }
        if (
          searchParams.iiu &&
          searchParams.iiu !== "" &&
          searchParams.iiu !== "null"
        ) {
          reportParameters.includeInactiveUsers = !!searchParams.iiu;
        }
        if (
          searchParams.ina &&
          searchParams.ina !== "" &&
          searchParams.ina !== "null"
        ) {
          reportParameters.includeNietAdministrators = !!searchParams.ina;
        }
        if (
          searchParams.id &&
          searchParams.id !== "" &&
          searchParams.id !== "null"
        ) {
          reportParameters.includeDirectors = !!searchParams.id;
        }
        if (
          searchParams.gl &&
          searchParams.gl !== "" &&
          searchParams.gl !== "null"
        ) {
          reportParameters.gradeLevels = searchParams.gl;
        }
        reportManager.updateReportParameters(reportParameters);
        setIsRunReportButtonEnabled(true);
      }
      setInitialReportParameters(reportParameters);
      setLoading(false);
    }
  }, [
    reportManager,
    accessToken,
    orgsLoaded,
    clustersLoaded,
    schoolYearsLoaded,
    report,
    reportManager?.AppUserState?.selectedSchoolYear,
  ]);

  useEffect(() => {
    if (report && reportManager?.ReportParameters) {
      const reportType = report.getReportType
        ? report.getReportType(reportManager.ReportParameters.reportType)
        : undefined;
      const requiredReportParameters = report.getRequiredReportParameters(
        getDefaultRequiredReportParameters(),
        reportType
      );

      const missingRequiredReportParameters =
        getMissingRequiredReportParameters(
          reportManager.ReportParameters,
          requiredReportParameters,
          getDefaultMissingRequiredReportParameters()
        );
      //console.log(missingRequiredReportParameters);
      const noneMissing = Object.values(missingRequiredReportParameters).every(
        (v) => v === false
      );
      setIsRunReportButtonEnabled(noneMissing);
    }
  }, [report, reportManager?.ReportParameters]);

  const getReportParameters = () => {
    const reportType = report.getReportType
      ? report.getReportType(
          reportManager.ReportParameters.reportType.toString()
        )
      : undefined;

    const reportTrdp = reportType
      ? report.getReportTrdp(reportType)
      : report.getReportTrdp();

    const parameters = reportType
      ? report.getReportParameters(reportManager.ReportParameters, reportType)
      : report.getReportParameters(reportManager.ReportParameters);

    const reportTitle = report.reportTitle;

    if (
      !parameters.SchoolYear &&
      !parameters.DateRangeStart &&
      !parameters.DateRangeEnd
    ) {
      parameters.SchoolYear = reportManager.AppUserState.selectedSchoolYear;
    } else if (parameters.SchoolYear) {
      parameters.DateRangeStart = null;
      parameters.DateRangeEnd = null;
    }

    return { reportTrdp, parameters, reportTitle };
  };

  const runReport = () => {
    const { reportTrdp, parameters, reportTitle } = getReportParameters();
    if (reportLoaded && reportLoaded === true) {
      if (viewerRef.current) {
        viewerRef.current.setReportSource({
          report: reportTrdp,
          parameters: parameters,
        });
        viewerRef.current.refreshReport();
      }
    } else {
      gaMgr.AddEvent(EventTypeEnum.View_Report, reportTitle);
      setReportLoaded(true);
    }
  };

  const createReportLink = () => {
    const parameters = reportManager.ReportParameters;
    const reportType = report.getReportType
      ? report.getReportType(reportManager.ReportParameters.reportType)
      : undefined;
    const parametersDisplayed = report.getReportParametersDisplay(
      getDefaultReportParametersDisplay(),
      reportType
    );

    const currentUrl = window.location.href.includes("?")
      ? window.location.href.substring(0, window.location.href.indexOf("?"))
      : window.location.href;

    let queryString = "?";
    queryString += `rt=${
      parametersDisplayed.isTeacherOrSchoolAdministratorTypeDisplayed ||
      parametersDisplayed.isRefinementOrReinforcementTypeDisplayed ||
      parametersDisplayed.isEvaluatorCertificationTypeDisplayed ||
      parametersDisplayed.isTeacherOverallAverageTypeDisplayed ||
      parametersDisplayed.isRoundingTypeDisplayed
        ? parameters.reportType
        : ""
    }`;

    queryString += `${queryString.length > 1 ? "&" : ""}oId=${
      parametersDisplayed.isOrganizationDisplayed
        ? parameters.organizationId
        : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}dId=${
      parametersDisplayed.isDistrictDisplayed ? parameters.districtId : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}sId=${
      parametersDisplayed.isSchoolDisplayed ? parameters.schoolId : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}cId=${
      parametersDisplayed.isClusterDisplayed ? parameters.clusterId : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}tIds=${
      parametersDisplayed.isTeachersDisplayed ? parameters.teacherUserIds : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}sy=${
      parametersDisplayed.isSchoolYearDisplayed
        ? parameters.schoolYear ?? reportManager.AppUserState.selectedSchoolYear
        : reportManager.AppUserState.selectedSchoolYear
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}sdr=${
      parametersDisplayed.isSelectDateRangeDisplayed
        ? parameters.selectDateRange.toString()
        : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}sd=${
      parametersDisplayed.isStartDateDisplayed ? parameters.startDate : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}ed=${
      parametersDisplayed.isEndDateDisplayed ? parameters.endDate : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}isal=${
      parametersDisplayed.isSummarizeAtLevelDisplayed
        ? parameters.isSummarizeAtLevel.toString()
        : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}iiu=${
      parametersDisplayed.isIncludeInactiveUsersDisplayed
        ? parameters.includeInactiveUsers.toString()
        : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}ina=${
      parametersDisplayed.isIncludeNietAdministratorsDisplayed
        ? parameters.includeNietAdministrators.toString()
        : ""
    }`;
    queryString += `${queryString.length > 1 ? "&" : ""}id=${
      parametersDisplayed.isIncludeDirectorsDisplayed
        ? parameters.includeDirectors.toString()
        : ""
    }`;

    queryString += `${queryString.length > 1 ? "&" : ""}gl=${
      parametersDisplayed.isIncludeGradeLevels
        ? parameters.gradeLevels.toString()
        : ""
    }`;

    return `${currentUrl}${queryString}`;
  };

  const copyReportLinkToClipboard = () => {
    const reportLink = createReportLink();

    navigator.clipboard.writeText(reportLink);
    document.querySelector(".tooltip-text").style.visibility = "visible";
    let toolTipInterval = setInterval(() => {
      clearInterval(toolTipInterval);
      document.querySelector(".tooltip-text").style.visibility = "hidden";
    }, 1000);
  };

  const loadTelerikReportViewer = () => {
    if (reportLoaded && reportLoaded === true) {
      const { reportTrdp, parameters } = getReportParameters();

      return (
        <TelerikReportViewer
          ref={viewerRef}
          authenticationToken={accessToken}
          serviceUrl={config.REPORTS.SERVICE_URL}
          reportSource={{
            report: reportTrdp,
            parameters: parameters,
          }}
          viewerContainerStyle={{
            position: "absolute",
            left: "20rem",
            right: "2.5rem",
            top: "1rem",
            bottom: "7rem",
            overflow: "hidden",
            clear: "both",
            fontFamily: "Raleway, sans-serif",
          }}
          viewMode="INTERACTIVE"
          scaleMode="SPECIFIC"
          scale={1.0}
          enableAccessibility={false}
        />
      );
    }
    return null;
  };

  const createSPCall = () => {
    const { parameters } = getReportParameters();
    const keys = Object.keys(parameters);
    let spCall = `EXECUTE [proc_rpt] @CurrentUserId=null,@CurrentUserTypeName='AllData',`;
    _.each(keys, (k) => {
      spCall += `@${k}=${
        parameters[k] === true
          ? "1"
          : parameters[k] === false
          ? "0"
          : parameters[k]
          ? "'" + parameters[k] + "'"
          : "NULL"
      },`;
    });

    spCall = spCall.substring(0, spCall.length - 1);

    navigator.clipboard.writeText(spCall);
    document.querySelector(".tooltip-text").style.visibility = "visible";
    let toolTipInterval = setInterval(() => {
      clearInterval(toolTipInterval);
      document.querySelector(".tooltip-text").style.visibility = "hidden";
    }, 1000);
  };

  const navBack = () => {
    if (visited === "VISITED") {
      navigate(-1);
    } else {
      navigate("/reports");
    }
  };

  return (
    <>
      {hasPermission === false && <AccessDeny />}

      {hasPermission === true && (
        <MainLayout>
          <ScreenWrapper loading={loading} loadingError={loadingError}>
            <div className="control-box-wrapper">
              <div className="control-box-list">
                <ReportFilter
                  initialReportParameters={initialReportParameters}
                  reportUrl={url}
                  report={report}
                  orgs={orgs}
                  clusters={clusters}
                  schoolYears={schoolYears}
                  onReportParametersChange={(reportParameters) =>
                    reportManager?.updateReportParameters(reportParameters, url)
                  }
                />
                <div className="report-message-wrapper">
                  {
                    <div className="report-message">
                      <h4>
                        Please select an Organization and then click "Run
                        Report".
                      </h4>{" "}
                      <h4>
                        Clicking "Share Report" will provide you a link to you
                        to share your report with another EE PASS user.
                      </h4>
                    </div>
                  }
                </div>
              </div>

              <div className="screen-footer list">
                <div className="tooltip-text">Copied!</div>
                <ButtonControl
                  className={"btn-run-report"}
                  type={"play"}
                  disabled={!isRunReportButtonEnabled}
                  onClick={() => runReport()}
                >
                  Run Report
                </ButtonControl>
                <ButtonControl
                  className={"btn-copy-share-link"}
                  disabled={!isRunReportButtonEnabled}
                  onClick={() => copyReportLinkToClipboard()}
                >
                  Share Report
                </ButtonControl>
                {appUserManager?.canView("Development") && (
                  <>
                    &nbsp;
                    <ButtonControl type={"Okay"} onClick={() => createSPCall()}>
                      CREATE SP EXECUTE
                    </ButtonControl>
                  </>
                )}

                <div className="backbtn">
                  <ButtonControl type="cancel" onClick={() => navBack()}>
                    BACK
                  </ButtonControl>
                </div>
              </div>
            </div>

            {loadTelerikReportViewer()}
          </ScreenWrapper>
        </MainLayout>
      )}
    </>
  );
};

export default ReportMgmt;
