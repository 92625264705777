export const getDefaultReportParametersDisplay = () => ({
  isTeacherOrSchoolAdministratorTypeDisplayed: false,
  isRefinementOrReinforcementTypeDisplayed: false,
  isEvaluatorCertificationTypeDisplayed: false,
  isTeacherOverallAverageTypeDisplayed: false,
  isRoundingTypeDisplayed: false,
  isOrganizationDisplayed: false,
  isDistrictDisplayed: false,
  isSchoolDisplayed: false,
  isClusterDisplayed: false,
  isTeachersDisplayed: false,
  isSchoolYearDisplayed: false,
  isSelectDateRangeDisplayed: false,
  isStartDateDisplayed: false,
  isEndDateDisplayed: false,
  isSummarizeAtOrganizationDisplayed: false,
  isSummarizeAtLevelDisplayed: false,
  isIncludeInactiveUsersDisplayed: false,
  isIncludeNietAdministratorsDisplayed: false,
  isIncludeDirectorsDisplayed: false,
});
