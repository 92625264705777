import { useEffect, useState } from "react";
import _ from "lodash";
import PrintLayout from "../../../../components/layout/PrintLayout";
import moment from "moment";
import BitmovinPlayer from "../../../contentLibrary/app-components/Content/Player/BitmovinPlayer";

const GeneralSurveyPrintScreen = ({
  data,
  rubricData,
  rubricLoading,
  printingCompleted,
}) => {
  const [surveyQnA, setSurveyQnA] = useState(null);
  const [rubricResults, setRubricResults] = useState(null);
  const [print, setPrint] = useState(null);
  const [numMedia, setNumMedia] = useState(null);
  const [mediasLoaded, setMediasLoaded] = useState([]);
  const [mediaLoadingComplete, setMediaLoadingComplete] = useState(false);

  useEffect(() => {
    if (data && rubricLoading !== true) {
      const formattedSurveyQnA = _.reduce(
        _.orderBy(
          data.SurveyTemplate.SurveyTemplateFields,
          ["Sequence"],
          "asc"
        ),
        (newMap, x) => {
          const surveyItemDetails = _.find(
            data.Sections,
            (ds) => ds.SurveyTemplateFieldId === x.Id
          );
          const surveyItem = {
            FieldDisplayText: x.DisplayText,
            Value:
              x.SurveyFieldType == 3
                ? surveyItemDetails?.Value?.split(",")
                : surveyItemDetails?.Value,
            SurveyContents: surveyItemDetails?.SurveyContents?.first(),
          };
          newMap.push(surveyItem);
          return newMap;
        },
        []
      );
      const numOfMedia = formattedSurveyQnA.filter(
        (x) =>
          x.SurveyContents?.SurveyContentTypeId == 2 &&
          (x.SurveyContents?.File?.FileMimeType?.startsWith("image") ?? false)
      ).length;
      setNumMedia(numOfMedia);
      if (formattedSurveyQnA.length === 0 || numOfMedia === 0) {
        setMediaLoadingComplete(true);
      }

      setSurveyQnA(formattedSurveyQnA);
      if (rubricLoading === false) {
        setRubricResults(
          _.map(rubricData.data, (x) => {
            return {
              FieldDisplayText: x.tag.name,
              Value: x.selectedRating,
              Description: x.rubric[x.selectedRating] ?? "No Description Found",
            };
          })
        );
      }
      //setPrint(moment().toISOString(true))
    }
  }, [data, rubricLoading]);

  useEffect(() => {
    if (mediaLoadingComplete) {
      setPrint(moment().toISOString());
    }
  }, [mediaLoadingComplete]);

  const mediaLoaded = () => {
    let mL = [...mediasLoaded];
    mL.push(1);
    setMediasLoaded(mL);
    if (mL.length === numMedia) {
      setMediaLoadingComplete(true);
    }
  };

  const checkIsVideo = (fileUrl) => {
    return (
      fileUrl?.toLowerCase()?.includes(".mpeg") ||
      fileUrl?.toLowerCase()?.includes(".mpg") ||
      fileUrl?.toLowerCase()?.includes(".mpga") ||
      fileUrl?.toLowerCase()?.includes(".avi") ||
      fileUrl?.toLowerCase()?.includes(".mp4") ||
      fileUrl?.toLowerCase()?.includes("streaming.media.azure.net") ||
      fileUrl?.toLowerCase()?.includes("bitmovin-media-files")
    );
  };

  console.log(123);

  const renderSurveyFields = (field, i) => {
    return (
      <div className="review-row" key={i}>
        <div className="item-question name">{field?.FieldDisplayText}</div>

        {!_.isNil(field?.SurveyContents) ? (
          <div className="item-value-wrapper rating">
            {checkIsVideo(field?.SurveyContents?.File?.FileUrl) ? (
              //do we really need this?  all it does is print the first frame of the video player
              <BitmovinPlayer content={field?.SurveyContents?.File} />
            ) : (
              <figure className="img-wrapper">
                <img
                  onLoad={() => mediaLoaded()}
                  src={field?.SurveyContents?.File?.FileUrl}
                  alt=""
                />
              </figure>
            )}
          </div>
        ) : (
          <div className="item-value-wrapper rating">{field.Value}</div>
        )}
      </div>
    );
  };

  const renderRubricFields = (field, i) => {
    return (
      <div className="review-row" key={i}>
        <div className="item-question name rubric-name">
          {field?.FieldDisplayText}
        </div>
        <div className="item-value-wrapper rating rubric-rating">
          <div dangerouslySetInnerHTML={{ __html: field.Description }}></div>
        </div>
        <div className="item-value-wrapper rating rubric-rating">
          {field.Value}
        </div>
      </div>
    );
  };

  return (
    <PrintLayout
      print={print}
      cleanUp={printingCompleted}
      className={"survey-print"}
    >
      <div className="survey-screen-header">
        <div className="survey-header-row">
          <div className="survey-header-wrapper">
            <h1>{data?.GeneralSurveyName}</h1>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Survey Given By:</div>
            <div className="survey-subheader-right">
              {data?.AssignedByUser?.FullName}
            </div>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Teacher Surveyed:</div>
            <div className="survey-subheader-right">{data?.AssignedTo}</div>
          </div>
          {/* <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Start Date:</div>
                    <div className="survey-subheader-right"> {moment(data?.StartDate).format("MMMM D, YYYY")}</div>
                </div> */}
        </div>
      </div>
      <div className="survey-container">
        {!_.isEmpty(surveyQnA) && (
          <div className="survey">
            <div className="review-header">
              <div className=" review-header name">Survey Questions</div>
              <div className="rating">Responses</div>
            </div>
            {_.map(surveyQnA, (field, i) => {
              return renderSurveyFields(field, i);
            })}
          </div>
        )}
        {data.ShowRubric && (
          <div className="rubric">
            <div className="review-header">
              <div className=" review-header name rubric-name">
                Rubric Indicators
              </div>
              <div className="rating  rubric-rating">Descriptions</div>
              <div className="rating rubric-rating">Ratings</div>
            </div>
            {_.map(rubricResults, (field, i) => {
              return renderRubricFields(field, i);
            })}
          </div>
        )}
      </div>
    </PrintLayout>
  );
};

export default GeneralSurveyPrintScreen;
