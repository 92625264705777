import { useEffect, useState } from "react";
import _ from "lodash";
import PrintLayout from "../../../../components/layout/PrintLayout";
import moment from "moment";
import BitmovinPlayer from "../../../contentLibrary/app-components/Content/Player/BitmovinPlayer";

const TeacherSurveyPrintScreen = ({ data, printingCompleted }) => {
  const [surveyQnA, setSurveyQnA] = useState(null);
  const [print, setPrint] = useState(null);

  useEffect(() => {
    if (data) {
      const formattedSurveyQnA = _.reduce(
        _.orderBy(
          data.SurveyTemplate.SurveyTemplateFields,
          ["Sequence"],
          "asc"
        ),
        (newMap, x) => {
          const surveyItemDetails = _.find(
            data.SurveySections,
            (ds) => ds.SurveyTemplateFieldId === x.Id
          );
          const surveyItem = {
            FieldDisplayText: x.DisplayText,
            Value:
              x.SurveyFieldType == 3
                ? surveyItemDetails?.Value?.split(",")
                : surveyItemDetails?.Value,
            SurveyContents: surveyItemDetails?.SurveyContents?.first(),
          };
          newMap.push(surveyItem);
          return newMap;
        },
        []
      );
      setSurveyQnA(formattedSurveyQnA);
      setPrint(moment().toISOString(true));
    }
  }, [data]);

  const checkIsVideo = (fileUrl) => {
    return (
      fileUrl?.toLowerCase()?.includes(".mpeg") ||
      fileUrl?.toLowerCase()?.includes(".mpg") ||
      fileUrl?.toLowerCase()?.includes(".mpga") ||
      fileUrl?.toLowerCase()?.includes(".avi") ||
      fileUrl?.toLowerCase()?.includes(".mp4") ||
      fileUrl?.toLowerCase()?.includes("streaming.media.azure.net") ||
      fileUrl?.toLowerCase()?.includes("bitmovin-media-files")
    );
  };

  const renderSurveyFields = (field, i) => {
    return (
      <div className="review-row" key={i}>
        <div className="item-question name">{field?.FieldDisplayText}</div>

        {!_.isNil(field?.SurveyContents) ? (
          <div className="item-value-wrapper rating">
            {checkIsVideo(field?.SurveyContents?.File?.FileUrl) ? (
              <BitmovinPlayer content={field?.SurveyContents?.File} />
            ) : (
              <figure className="img-wrapper">
                <img src={field?.SurveyContents?.File?.FileUrl} alt="" />
              </figure>
            )}
          </div>
        ) : (
          <div className="item-value-wrapper rating">{field.Value}</div>
        )}
      </div>
    );
  };

  return (
    <PrintLayout
      print={print}
      cleanUp={printingCompleted}
      className={"survey-print"}
    >
      <div className="survey-screen-header">
        <div className="survey-header-row">
          <div className="survey-header-wrapper">
            <h1>{data?.Name}</h1>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Survey Given By:</div>
            <div className="survey-subheader-right">{data?.User.FullName}</div>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">Teacher Surveyed:</div>
            <div className="survey-subheader-right">
              {data?.SurveyedUser?.FullName}
            </div>
          </div>
          <div className="survey-subheader-wrapper">
            <div className="survey-subheader-left">School:</div>
            <div className="survey-subheader-right">{data?.School.Name}</div>
          </div>
          {/* <div className="survey-subheader-wrapper">
                    <div className="survey-subheader-left">Start Date:</div>
                    <div className="survey-subheader-right"> {FormatUTCDateTimeLong(data?.SurveyDate)}</div>
                </div> */}
        </div>
      </div>
      <div className="survey-container">
        <div className="survey">
          <div className="review-header">
            <div className=" review-header name">Survey Questions</div>
            <div className="rating">Responses</div>
          </div>
          {_.map(surveyQnA, (field, i) => {
            return renderSurveyFields(field, i);
          })}
        </div>
      </div>
    </PrintLayout>
  );
};

export default TeacherSurveyPrintScreen;
